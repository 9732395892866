export const getLink = (country, afterSlash, setLink) => {
    // const link = "https://amourhelper.com/tds/ae?tdsId=s5428sto_r&tds_campaign=s5428sto&s1=ps&utm_source=int&utm_sub=opnfnl&affid=fe6a20bd&subid=NowyLink&subid2={subid2}&clickid={clickid}";
    // const link = "https://date-till-late.us/tds/ae?tdsId=s5428sto_r&tds_campaign=s5428sto&s1=ps&utm_source=int&utm_sub=opnfnl&affid=fe6a20bd&subid2={subid}&subid=POST&clickid={clickid}"; //AdsEmpire
    // const link = "https://findlovesclick.life/?u=r8up60a&o=9fq0utm"; //LosPollos
    const link = "https://date-till-late.us/tds/ae?tdsId=s5428sto_r&tds_campaign=s5428sto&s1=ps&utm_source=int&utm_sub=opnfnl&affid=fe6a20bd&subid2={subid}&subid=POST&clickid={clickid}"; //ItMonetizeIt
    if (afterSlash === 'rejestracja') {
        setLink('https://date-till-late.us/tds/ae?tdsId=s5428sto_r&tds_campaign=s5428sto&s1=ps&utm_source=int&utm_sub=opnfnl&affid=fe6a20bd&subid2={subid}&subid=FP-PL&clickid={clickid}');
    } else if (afterSlash === 'register') {
        setLink('https://date-till-late.us/tds/ae?tdsId=s5428sto_r&tds_campaign=s5428sto&s1=ps&utm_source=int&utm_sub=opnfnl&affid=fe6a20bd&subid2={subid}&subid=FP_ENG&clickid={clickid}');
    } else if (afterSlash === 'anmeldung') {
        setLink('https://date-till-late.us/tds/ae?tdsId=s5428sto_r&tds_campaign=s5428sto&s1=ps&utm_source=int&utm_sub=opnfnl&affid=fe6a20bd&subid2={subid}&subid=FP-DE&clickid={clickid}');     
    } else if (afterSlash === 'registrering') {
        setLink('https://date-till-late.us/tds/ae?tdsId=s5428sto_r&tds_campaign=s5428sto&s1=ps&utm_source=int&utm_sub=opnfnl&affid=fe6a20bd&subid2={subid}&subid=FP-SE&clickid={clickid}');  
    } else if (afterSlash === 'registrazione') {
        setLink('https://date-till-late.us/tds/ae?tdsId=s5428sto_r&tds_campaign=s5428sto&s1=ps&utm_source=int&utm_sub=opnfnl&affid=fe6a20bd&subid2={subid}&subid=FP-IT&clickid={clickid}');
    } else if (afterSlash === 'insta') {
        setLink('https://date-till-late.us/tds/ae?tdsId=s5428sto_r&tds_campaign=s5428sto&s1=ps&utm_source=int&utm_sub=opnfnl&affid=fe6a20bd&subid2={subid}&subid=INSTA&clickid={clickid}');
    } else if (afterSlash === 'alicja6') {
        setLink('https://re-direct3.eu/p/eVlP/4J8d/r1f8'); // Alicja Karnas
    } else if (afterSlash === 'aniawalet') {
        setLink('https://redirecting8.eu/p/eVlP/DMQY/j69l'); // Anna Walet
    } else if (afterSlash === 'paula69') {
        setLink('https://redirecting8.eu/p/eVlP/DMQY/j69l'); // Paula Kubicka
    } else if (afterSlash === 'tilly69') {
        setLink('https://redirecting8.eu/p/eVlP/wUp8/GNe2'); // Tilly Lawson
    } else if (afterSlash === 'leah69') {
        setLink('https://date-till-late.us/tds/ae?tdsId=s5428sto_r&tds_campaign=s5428sto&s1=ps&utm_source=int&utm_sub=opnfnl&affid=fe6a20bd&subid2={subid}&subid=Leah_Poole&clickid={clickid}'); // Leah Poole
    } else if (afterSlash === 'kate69') {
        setLink(link); // Kate Fox
    } else if (afterSlash === 'caroline69') {
        setLink(link); // Caroline Lupa
    } else if (afterSlash === 'amber69') {
        setLink(link); // Amber Brown

    // Fanpage
    } else if (afterSlash === 'profile?pamela9') { // Lonely Pamela
        setLink('https://date-till-late.us/tds/ae?tdsId=s5428sto_r&tds_campaign=s5428sto&s1=ps&utm_source=int&utm_sub=opnfnl&affid=fe6a20bd&subid2={subid}&subid=Lonely_Pamela&clickid={clickid}');
    } else if (afterSlash === 'profil?alicja34') { // Alicja Karnas
        setLink('https://re-direct3.eu/p/eVlP/4J8d/r1f8');
    } else {
        if (country === 'United States') {
            setLink('https://date-till-late.us/tds/ae?tdsId=s5428sto_r&tds_campaign=s5428sto&s1=ps&utm_source=int&utm_sub=opnfnl&affid=fe6a20bd&subid2={subid}&subid=POST&clickid={clickid}'); //AdsEmpire SmartLink
        } else if (country === 'Poland') {
            setLink('https://re-direct3.eu/p/eVlP/4J8d/Y34h'); //MyLead SnapDate
        } else if (country === 'Sweden'){
            setLink('https://zomzke.efortiessdates.com/?utm_source=da57dc555e50572d&s1=200353&s2=2036682&ban=fb&j1=1'); //IMonetizeIt SmartLink
        } else if (country === 'Australia') {
            setLink('https://click-1.pl/p/eVlP/wUp8/1HEv'); //MyLead SmartLink VIII
        } else if (country === 'Germany') {
            setLink('https://sweethotgirls.one/?u=r8up60a&o=9fvew2y'); //LosPollos SmartLink
        } else {
            setLink(link);
        }
    }
    console.log(country);
}